$address-border: $color-mid-gray;
$address-background-color: $color-light-gray;
$invalid-error-color: $color-red;
$invalid-background: $color-white;

.address-search-results {
  z-index: 999;
  border: 1px solid $address-border;
  background-color: $address-background-color;
  width: 100%;
  height: 200px;
  overflow: scroll;
  font-size: 12px;
}

#cboxLoadedContent {
  .address-overlay {
    .address-form {
      &__country {
        padding-bottom: 1em;
      }
    }
  }
  .product-fave-confirm {
    margin-left: -50px;
    padding: 1.75em 0;
  }
}

.orders-list__table {
  .order-date {
    width: 27%;
  }
  .order-cost {
    width: 30%;
  }
  .order-details {
    .product__tracking {
      width: 17%;
    }
    .product__actions {
      width: 20%;
    }
  }
}

.invalid_marker {
  font-size: 2em;
  position: absolute;
  right: 30px;
  bottom: 22px;
  background-color: $invalid-background;
  color: $invalid-error-color;
}

#max-quantity-container {
  padding: 30px;
}

.mpp {
  .mpp__regimen-quiz__tab {
    .mpp__regimen-quiz__tab__inner {
      padding-top: 4px;
      line-height: 39px;
      text-align: center;
      font-size: 16px;
      display: block;
    }
  }
}

.page-footer {
  .menu__list {
    .lpStaticButton {
      td {
        text-align: center;
        padding: 0;
      }
    }
    .lpPoweredBy,
    .lpEmtStarRating {
      display: none;
    }
  }
}

.page-branding__logo {
  margin-top: 5px;
}

.page-wrapper {
  .page-header__utils {
    .menu--lvl-2 {
      .block.block-nodeblock {
        width: 500px;
        @include breakpoint($landscape-up) {
          &.block-template-gnav-mini-facials-v1 {
            width: 742px;
          }
        }
      }
    }
  }
}

nav.page-header__nav__main {
  .menu.menu--lvl-1.menu--children-6 {
    .menu.menu--lvl-2.menu--children-1 {
      .product-grid-wrapper {
        .product-grid.js-grid.js-product-grid {
          .product-grid__content {
            .product-grid__item.js-grid-item.js-product-grid-item {
              .js-product.product-brief {
                padding-top: 5px;
              }
              img.product-brief__img {
                width: 50%;
              }
              .product-brief__header {
                .product-brief__title {
                  font-size: 10px;
                  height: auto;
                }
                .product-brief__subtitle {
                  font-size: 20px;
                  height: auto;
                }
                .product-brief__subline {
                  font-size: 1em;
                  margin-top: 8px;
                  margin-bottom: 8px;
                }
              }
              .product-brief__price-points {
                .product-brief__price {
                  font-size: 16px;
                  margin-bottom: 8px;
                }
              }
              .product-brief__footer {
                padding-top: 15px;
                a.product-brief__cta.product-brief__cta--shop-now.js-spp-link {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.js-search_results__products {
  .product-quickshop__info {
    .product-quickshop__footer {
      .product-brief__ctas--desktop.mobile-hidden {
        .product-brief__cta.product-brief__cta--quickshop.link {
          display: inline-block;
          min-width: 70px;
        }
      }
    }
  }
}

.section-store-locator {
  .store-locator {
    &__main {
      .doors_results {
        .store-locator {
          &__result {
            height: 210px;
            .distance {
              right: -15px;
              width: 35%;
            }
          }
        }
      }
    }
  }
}

.spp-page {
  .product-full-v2 {
    .product-full__sku-details {
      &-wrapper {
        height: auto;
      }
    }
  }
}
