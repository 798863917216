//Olapic style overrides to apply TW fonts
.olapic .main-container {
  @include font--text;
  color: $color-text;
  font-size: $base-font-size;
  line-height: $base-line-height;
  letter-spacing: 0.01em;
  position: relative;
  width: 100%;
  margin: 0 auto;
  font-weight: 300;
}

.olapic .product-list-item-title {
  font-size: 14px;
}

.olapic h3 {
  margin: 0;
  padding: 30px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.social-gallery {
  &__title {
    font-size: 34px;
    font-family: $font--subheading;
  }
  &__copy {
    font-weight: bold;
    font-family: $font--text;
  }
}
