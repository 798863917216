$color-dark-black: $color-black;
$color-grey: $color-mid-gray;

$font--heading: $font--futura-book;
$font--heading--secondary: 'Futura-Book', 'Microsoft YaHei', sans-serif;
$font--heading--secondary--italic: 'Futura-Book', 'Microsoft YaHei', sans-serif;
$font--subheading: $font--futura-demi;
$font--text: 'Futura-Book', 'Microsoft YaHei', sans-serif;
$font--text--italic: 'Futura-Book', 'Microsoft YaHei', sans-serif;
$font--cta: 'Futura-Book', 'Microsoft YaHei', sans-serif;
$font--typewriter: 'Futura-Book', 'Microsoft YaHei', sans-serif;
$font--subheading--secondary: 'Futura-Book', 'Microsoft YaHei', sans-serif;
$font--subheading--light: $font--futura-demi;

// OR TW creative refresh
$font--futura--text: $font--futura-book;

@mixin font--heading {
  font-family: $font--heading;
  text-transform: uppercase;
  // Global change requested by brand:
  // letter-spacing: 0.1em;
}

@mixin font--subheading {
  // I am well aware antialiasing is a bad thing to do globally:
  // http://usabilitypost.com/2012/11/05/stop-fixing-font-smoothing/
  // The brand, however, wants what it wants.
  @include font-smoothing(antialiased);
  font-family: $font--subheading;
}

@mixin font--text {
  font-family: $font--text;
  // Global change requested by brand:
  // letter-spacing: 0.025em;
}

@mixin font--text-alt {
  font-family: $font--text;
  // Global change requested by brand:
  // letter-spacing: 0.05em;
}

@mixin font--cta {
  font-family: $font--cta;
}

.sign-in-page {
  .account-page__section--heading {
    font-family: $font--text;
  }
}

.page-footer__primary {
  .menu__link--lvl-2 {
    font-family: $font--cta;
  }
}

.page-footer__secondary {
  font-family: $font--cta;
  .menu__link--lvl-1 {
    font-family: $font--cta;
  }
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='search'],
input[type='password'],
select,
textarea,
.button,
.form-submit {
  font-family: $font--subheading;
}

.page-header__meta-nav__inner {
  .gnav-offers-block__list__item {
    a.gnav-offers-block__offer--link {
      font-family: $font--subheading;
    }
  }
}

.gnav-dropdown {
  .menuref {
    .menu__link {
      font-family: $font--text;
    }
  }
}

.orders-list {
  &__table {
    &--cell {
      font-family: $font--cta;
      &.order-cost {
        .product__price {
          font-family: $font--text;
        }
      }
    } // &--cell
    .account-products {
      &__item {
        font-family: $font--cta;
      } // &__item
    } // .account-products
    .order-details {
      .product {
        &__actions {
          .product__price {
            font-family: $font--text;
          }
        } // &__actions
        &__qty {
          .product__price {
            @include breakpoint($small-up) {
              font-family: $font--text;
            }
          }
        }
      }
    }
  }
}

.order-summary-panel__value {
  font-family: $font--text;
}

.checkout .cart-items,
.account-page .account-products {
  &__item {
    padding: 1em;
    &--desc,
    &--qty {
      font-family: $font--cta;
    }
    &--price,
    &--total {
      font-family: $font--text;
    }
  }
}

.samples-panel {
  .checkout__panel,
  .samples-panel {
    &--heading {
      font-family: $font--subheading;
    }
  }
  .details {
    .product-size {
      font-family: $font--cta;
    }
  }
}

.samples-page {
  font-family: $font--cta;
}

ul.product-list .product {
  font-family: $font--subheading;
}

.wp-overlay-container {
  .wp-overlay-content {
    .wp-pop-up-msg {
      p {
        font-family: $font--text;
      }
      .wp-note {
        font-family: $font--subheading;
      }
      a {
        font-family: $font--cta;
      }
    }
    .remove-buttons {
      a {
        font-family: $font--cta;
      }
    }
  }
}

#samples-panel {
  .continue-shopping-outer-container {
    a {
      font-family: $font--cta;
    }
  }
}

.page-origins {
  &__header {
    &-copy {
      font-family: $font--subheading;
      em {
        font-family: $font--subheading;
      }
    }
    &-information {
      font-family: $font--subheading;
    }
  }
  &__milestone {
    .origins__milestone {
      &-content {
        font-family: $font--subheading;
      }
      &-year {
        font-family: $font--subheading;
      }
      &-copy {
        font-family: $font--subheading;
        em {
          font-family: $font--subheading;
        }
      }
    }
  }
  &__facts {
    &-copy {
      font-family: $font--subheading;
      em {
        font-family: $font--subheading;
      }
    }
  }
}

.our-commitment {
  &__copy {
    font-family: $font--subheading;
    em {
      font-family: $font--subheading;
    }
  }
}

.discover__description {
  font-family: $font--subheading;
}

.journey-plant-formula__subheading {
  font-family: $font--subheading;
}

@mixin h1 {
  @include font--heading;
  // font-size: 70px;
  font-size: 105px; // This is crazy.
}
%h1 {
  @include h1;
}

@mixin h2 {
  @include font--heading;
}
%h2 {
  @include h2;
}

@mixin h3 {
  @include font--heading;
}
%h3 {
  @include h3;
}

@mixin h4 {
  @include font--heading;
}
%h4 {
  @include h4;
}

@mixin h5 {
  @include font--heading;
}
%h5 {
  @include h5;
}

@mixin h6 {
  @include font--heading;
}
%h6 {
  @include h6;
}

@mixin h7 {
  @include font--heading;
}
%h7 {
  @include h7;
}

@mixin h8 {
  @include font--subheading;
}
%h8 {
  @include h8;
}

@mixin h9 {
  @include font--subheading;
}
%h9 {
  @include h9;
}

@mixin h10 {
  @include font--subheading;
}
%h10 {
  @include h10;
}

@mixin h11 {
  @include font--subheading;
}
%h11 {
  @include h11;
}

@mixin h12 {
  @include font--subheading;
}
%h12 {
  @include h12;
}

@mixin h13 {
  @include font--subheading;
}
%h13 {
  @include h13;
}

@mixin h14 {
  @include font--subheading;
}
%h14 {
  @include h14;
}

///
/// Text
///
@mixin t1 {
  @include font--text;
}
%t1 {
  @include t1;
}

@mixin t2 {
  @include font--text;
}
%t2 {
  @include t2;
}

@mixin t3 {
  @include font--text;
}
%t3 {
  @include t3;
}

@mixin t4 {
  @include font--text;
}
%t4 {
  @include t4;
}

@mixin t5 {
  @include font--text;
}
%t5 {
  @include t5;
}

@mixin t6 {
  @include font--text-alt;
}
%t6 {
  @include t6;
}

@mixin t7 {
  @include font--text-alt;
}
%t7 {
  @include t7;
}

@mixin t8 {
  @include font--text-alt;
}
%t8 {
  @include t8;
}

@mixin t9 {
  @include font--text-alt;
}
%t9 {
  @include t9;
}

@mixin t10 {
  @include font--cta;
}
%t10 {
  @include t10;
}

///
/// Links
///
@mixin a1 {
  @include font--cta;
}
%a1 {
  @include a1;
}

@mixin link($color: $color-link, $hover-color: $color-link-hover) {
  @include a1;
  @include transition(color, 200ms);
  color: $color;
  cursor: pointer;
  // Don't do this.
  // text-decoration: underline;
  &:hover {
    color: $hover-color;
  }
}

.link {
  @include link;
}
%link {
  @include link;
}
