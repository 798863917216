.page-header__utils {
  .menu__list {
    .utility-nav {
      &__item {
        .utility-nav__menu-items {
          line-height: 32px;
          font-size: 20px;
          position: relative;
          padding-top: 0;
          a,
          .utility-nav__menu-link {
            font-family: $font--subheading;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
  }
  .menu__link--lvl-1 {
    font-family: $font--subheading;
  }
}

.utility-nav-mob__item {
  font-family: $font--subheading;
}

.gnav-formatter {
  .gnav-menu-item {
    &__title-wrap,
    &__link {
      font-family: $font--subheading--light;
      font-size: 19px;
      letter-spacing: 0.05em;
    }
  }
  &--lvl-1 {
    @include breakpoint($large-up) {
      line-height: 70px;
    }
  }
  &--lvl-3 {
    .gnav-menu-link {
      font-family: $font--subheading;
      font-size: 16px;
      line-height: 1.2;
    }
  }
}

.page-header {
  &.alt-nav-mob,
  &__nav {
    .gnav-util--search-v2 {
      &.gnav-util {
        .gnav-util__content__inner {
          form.gnav-search {
            input[type='search'] {
              font-family: $font--subheading;
            }
          }
        }
      }
    }
  }
}

.footer-section-primary {
  font-family: $font--futura-book;
  &__title {
    font-family: $font--subheading;
  }
  .menu__link {
    font-family: $font--futura-book;
  }
  .signup-footer__fields {
    .signup-footer__email {
      input,
      label {
        font-family: $font--futura-book;
      }
    }
  }
}

.footer-links {
  &__items {
    font-family: $font--futura-book;
    .footer-links__pp-link {
      font-family: $font--futura-book;
    }
  }
}

.product-brief {
  &__subline,
  &__benefit {
    font-family: $font--subheading;
    width: 100%;
  }
  &__cta-spp-link {
    .product-brief__spp-link {
      font-family: $font--futura-book;
    }
  }
  &.product-brief-v2 {
    .product-brief {
      &__cta-add-to-bag {
        .product-add-to-bag {
          font-family: $font--subheading;
        }
      }
      &__benefit-label {
        font-family: $font--subheading--light;
      }
    }
  }
}

.product-full {
  &__subline,
  &__attributes {
    font-family: $font--subheading;
  }
  .product-add-to-favorites {
    font-family: $font--subheading;
  }
  &.product-full-v2 {
    .product-full {
      &__subheading,
      &__attributes-label {
        font-family: $font--subheading;
      }
      &__add-to-bag {
        .product-add-to-bag {
          font-family: $font--subheading;
        }
      }
      &__overview {
        font-family: $font--subheading;
      }
    }
    .product-offer__text {
      font-family: $font--subheading;
    }
    .spp-sticky-add-to-bag {
      .product-add-to-bag {
        font-family: $font--subheading;
      }
    }
  }
}

.spp-cross-sells__header {
  font-family: $font--subheading;
}

.product-badge__text {
  font-family: $font--subheading--light;
}

.spp-sticky-add-to-bag__description {
  .spp-sticky-add-to-bag {
    &__subline {
      font-family: $font--subheading;
    }
    &__subhead {
      font-family: $font--subheading--light;
    }
  }
}

.optanon-show-settings-popup-wrapper {
  .optanon-show-settings-button {
    &.optanon-toggle-display {
      .optanon-show-settings-middle {
        .optanon-show-settings {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.checkout__content {
  .cart-items__item--qty {
    &.bogo-qty {
      margin-top: 4px;
    }
  }
}

.regimen-finder {
  .quiz-landing-page-formatter {
    &__results-footer {
      a.retake-quiz {
        @include breakpoint($medium-up) {
          width: 13%;
          font-size: 20px;
        }
      }
      a.product__button--add-to-bag {
        @include breakpoint($medium-up) {
          font-size: 16px;
        }
      }
    }
  }
}

.custom-text.mantle-custom-text.format--default.style--bare {
  font-size: 15px;
  @include breakpoint($medium-up) {
    font-size: 24px;
  }
}

.product-full.product-full-v2 {
  .product-full__overview-trigger {
    &.current {
      font-weight: 900;
    }
  }
}
