// Overrides Account Settings Styling
#settings {
  .social-login-section {
    border: solid 1px $color-light-gray;
    margin: 20px 0;
    padding: 20px;
    text-align: $ldirection;
    .profile-page {
      .social-info {
        &__connect {
          border-bottom: 1px solid $color-light-gray;
          padding-bottom: 10px;
        }
      }
    }
    .settings-info {
      &__content {
        display: block;
      }
      &__header {
        border: none;
        font-size: 22px;
        @include breakpoint($medium-up) {
          font-size: 30px;
        }
      }
      .footer-social-links {
        display: inline-block;
        border-bottom: solid 1px $color-light-gray;
      }
      .social {
        &-connect,
        &-disconnect {
          cursor: pointer;
          float: $rdirection;
          margin-top: 20px;
          a {
            text-decoration: none;
          }
        }
      }
    }
    .social-text {
      font-size: 12px;
      @include breakpoint($medium-up) {
        font-size: 15px;
      }
    }
  }
}

.disconnect-confirm-overlay {
  .disconnect {
    &-no,
    &-yes {
      float: $ldirection;
      padding: 10px;
      margin: 10px;
      background-color: $color-black;
      color: $color-white;
      cursor: pointer;
      a {
        color: $color-white;
        text-decoration: none;
      }
    }
    &-overlay {
      margin: 10px;
      padding: 10px;
      text-align: $ldirection;
      &-header {
        font-size: 1.2rem;
        font-weight: 700;
      }
      &-description {
        font-size: 1rem;
      }
    }
  }
}
