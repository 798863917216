.change-password {
  #registration_content {
    .password-field {
      &__info {
        @include breakpoint($medium-up) {
          right: 97%;
          width: 140px;
          bottom: 10px;
        }
      }
    }
  }
  .profile__change-password-overlay {
    &#colorbox {
      #cboxWrapper {
        #cboxContent {
          text-align: left;
        }
      }
    }
    .profile__reset-password {
      .profile-info {
        &__rules {
          li {
            display: flex;
          }
          &--popup {
            column-count: 2;
          }
        }
      }
    }
  }
}
// Password strengthening tooltip
.my-appt-change-password {
  .password-field {
    position: relative;
    height: auto;
    &__info {
      @include breakpoint($medium-up) {
        width: 160px;
        border: 1px solid $color-gray;
      }
      background-color: $color-white;
      z-index: 99;
      margin-top: 15px;
      &:before {
        @include breakpoint($medium-up) {
          content: '';
          position: absolute;
          top: 62px;
          left: 75px;
          border: 5px solid transparent;
          border-bottom-color: $color-gray;
        }
      }
    }
    &__rules {
      @include breakpoint($medium-up) {
        column-count: 1;
      }
      column-count: 2;
      padding: 10px;
      font-size: 11px;
      list-style: none;
      li {
        display: flex;
        align-items: center;
        padding: 3px 0;
        color: $color-gray;
        -webkit-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        white-space: nowrap;
        &:before {
          content: $cross;
          display: inline-block;
          color: $color-red;
          font-size: 16px;
          line-height: 0;
          margin-left: 6px;
          -webkit-transition: 0.2s;
          -ms-transition: 0.2s;
          -o-transition: 0.2s;
          transition: 0.2s;
        }
        &.pass {
          color: $color-success;
          &:before {
            color: $color-success;
            content: $tick;
            text-shadow: 0 0 8px ($color-black, 0.5);
          }
        }
      }
    }
  }
}
