@media (min-width: 1024px) {
  .page-header__nav__main {
    .menu__link--lvl-1 {
      padding-top: 2px;
      transition: color, 200ms;
      position: relative;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .page-header__meta-nav__inner {
    .page-header__offers {
      .gnav-offers-block {
        a.gnav-offers-block__headline-link {
          .gnav-offers-block__headline {
            font-family: 'Futura-Demi', 'Microsoft YaHei', sans-serif;
            font-size: 20px;
            padding-top: 2px;
            line-height: 32px;
            display: block;
          }
        }
      }
    }
    .page-header__utils {
      .menu--lvl-1 {
        .menu__list--lvl-1 {
          .menu__item--lvl-1 {
            .menu__link--lvl-1 {
              font-family: 'Futura-Demi', 'Microsoft YaHei', sans-serif;
              font-size: 20px;
              padding-top: 2px;
              line-height: 32px;
              transition: color, 200ms;
              position: relative;
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
  .field-content {
    .content {
      .inline-video__content {
        margin-top: 14%;
      }
    }
  }
}

@media (min-width: 1024px) {
  .gnav-offers-block__offer {
    line-height: 32px !important;
  }
}

.profile-info__fieldset,
.profile-form-container {
  .title_container {
    padding-bottom: 1em;
  }
}

#registration-wrapper {
  .profile-form-container {
    margin-top: 20px;
  }
  fieldset.fs {
    width: 100%;
    margin: 0 1.33em 0 0;
    .form_element {
      width: 50%;
      margin: 0px;
      input {
        width: 100%;
      }
    }
    .form_element.pc_email_container {
      width: 100%;
    }
    .form-item.ship_type_container {
      clear: both;
      width: 100%;
    }
  }
  .fieldset__3col.birthday {
    .form_element {
      margin: 0;
      width: 33.33%;
      select.error {
        border-color: $color-red;
      }
    }
    label[for='birthday-fields'] {
      margin-top: 0.4em;
    }
  }
  .form_element.gender_container {
    label {
      float: left;
      width: auto;
    }
  }
  .form_element.phone2_container {
    input[type='text'] {
      display: block;
      width: 176px;
    }
  }
  .email_promotions {
    .form_element.radio {
      width: 100%;
      label {
        width: 100%;
      }
    }
  }
  .form_element.privacy_policy_container {
    margin-bottom: 20px;
  }
}

.account-page__content {
  .profile-info {
    &__fieldset {
      .national_id_container {
        float: right;
        height: 66px;
        line-height: 2.5em;
        padding-bottom: 1em;
        width: 50%;
      }
    }
  }
  .fieldset__3col.birthday {
    .form-item {
      margin: 0;
      width: 33.33%;
      select.error {
        border-color: $color-red;
      }
    }
  }
}

.orders-list {
  .orders-list__table {
    .orders-list__table--head {
      .orders-list__table--cell {
        font-size: 20px;
      }
    }
    .orders-list__table--group {
      .orders-list__table--row {
        .order-status {
          font-size: 20px;
        }
      }
      .order-details {
        .order-products {
          .product__actions {
            .product__status {
              font-size: 20px;
            }
          }
          .product__qty {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.address-form {
  .title,
  .ship_type {
    span {
      display: block;
      padding-bottom: 5px;
    }
  }
  .phones.address-form__phones {
    margin-bottom: 10px;
  }
}

.sign-in-page {
  .account-page__section--heading {
    text-align: center;
  }
  .new-account {
    .form-text {
      width: 99%;
    }
  }
  .return-user,
  .new-account {
    .form-item {
      margin: 0 0 20px;
    }
  }
}

.orders-list__table {
  .orders-list__table--row.order-info {
    .orders-list__table--cell {
      &.order-status {
        font-size: 19px;
      }
    }
  }
  .order-details {
    .product__qty {
      width: 19%;
    }
  }
}

.email_promotions {
  .pc_email_promo_container {
    margin-bottom: 10px;
  }
}

#registration_content {
  margin: 30px auto;
  width: 77%;
  #content {
    width: 76%;
    float: left;
  }
  #sidebar {
    width: 20%;
    margin-left: 25px;
    margin-top: 5px;
    float: left;
  }
  #registration {
    .account-page__section {
      .profile {
        .title_container {
          .label-class {
            span.label-content {
              display: inline !important;
            }
          }
        }
      }
    }
  }
}

span.radio_label {
  display: inline !important;
}

.order-details-page {
  .account-page__section.order-details {
    h4 {
      margin-bottom: 0px;
    }
  }
  .order-products {
    .product__price {
      float: left;
      margin-left: 0;
      padding: 0 0.5em;
      width: 10%;
      .price {
        font-size: 18px;
      }
    }
    .product__total {
      .price {
        font-size: 18px;
      }
    }
    &__header {
      &--total {
        text-align: right;
      }
      &--qty {
        text-align: center;
      }
    }
  }
  .order-totals {
    .order-summary-panel__label {
      font-size: 18px;
    }
    .price {
      font-size: 18px;
    }
  }
}

html select::-ms-expand {
  display: none;
}

.address-item__controls,
.payment-item__controls {
  .link--default {
    display: block;
    margin-top: 5px;
  }
}

.address-book__others {
  h4.address-book__subheading {
    width: 50%;
    clear: both;
  }
}

#colorbox {
  .address-delete {
    .address-delete__header {
      width: 95%;
    }
  }
}

.password-reset-page {
  #password_reset {
    .password-reset__fieldset {
      max-width: none;
      .form-item.password-reset__item {
        width: 45%;
      }
      .form-submit {
        float: left;
        clear: both;
      }
    }
  }
}

.regimen-quiz {
  .regimen-quiz__actions__reset-link {
    margin-left: 0;
    width: auto;
  }
}

.regimen-page__results {
  .regimen-page__results__title {
    line-height: 64px;
  }
  .menu__list--lvl-1 {
    .menu__item--lvl-1 {
      a {
        line-height: 38px;
        .menu__item--lvl-2 {
          .menu__item--lvl-2 {
            a {
              line-height: 50px;
            }
          }
        }
      }
    }
  }
}

.page-header__nav__supplemental {
  .gnav-util--account {
    .gnav_lyl_rewards {
      display: none;
    }
    &.active-util {
      .signin-block {
        .signin-block__authed {
          width: 650px;
          &__nav {
            width: 350px;
          }
          &__nav__title {
            border-bottom: 1px solid $color-darker-gray;
            padding-bottom: 14px;
          }
          &__status {
            display: none;
          }
        }
      }
    }
  }
}

.product-info {
  .product-offer {
    &__header,
    &__text {
      display: inline-block !important;
      font-family: SimSun, 'Futura-Book', sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
    }
    &__text {
      border-left: 1px solid $color-light-gray !important;
      text-align: left;
    }
  }
}

.page-footer__logo {
  .kbanklogo {
    text-align: center;
    overflow: hidden;
    img {
      padding-top: 5px;
    }
  }
}

.site-email-signup {
  .site-email-signup__fields {
    input[type='tel'] {
      display: none;
    }
  }
}

.email_promotions {
  #sms-promotions {
    display: none;
  }
}

.order-totals {
  .estimated_total {
    font-weight: bold;
  }
}

.adpl {
  .district.select {
    label {
      display: block;
    }
  }
}

.password-request-page {
  .checkout__panel {
    border-top: 0;
    header {
      padding: 0;
    }
    .checkout__panel--content {
      padding: 0;
    }
  }
}

.favorites-none {
  padding: 10px 0 0 15px;
}

.favorites-page__tabs {
  .favorites-page__tab {
    &.active {
      position: relative;
    }
  }
}

.gnav-util {
  .trustmark-logo-div img {
    width: 65px;
  }
}

.favorites-create-wishlist__item label {
  display: block;
}

#colorbox {
  #cboxWrapper {
    #cboxContent.AddToListContent {
      width: 100% !important;
      height: auto !important;
    }
  }
}
