$font--pwr--subheading: $font--futura-book;
$detail_color: $color-dark-gray;

@mixin font--pwr--subheading {
  font-family: $font--pwr--subheading;
  color: $color-black;
  font-weight: normal;
}

#pr-write,
#pr-reviewdisplay,
#pr-reviewsnippet,
.product-full__info,
.product-grid--quickshop,
#pr-questiondisplay {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        a,
        span {
          font-size: 12px !important;
        }
        &::before {
          margin: 0;
        }
      }
    }
  }
  .product-info__review-summary {
    .pr-review-snippet-container {
      .pr-snippet-stars-reco-inline {
        .pr-snippet-read-and-write {
          a {
            padding: 0;
          }
        }
      }
    }
  }
}

.spp-page {
  .product-full__info {
    .p-w-r {
      .pr-snippet-read-and-write {
        a {
          padding: 0;
          font-size: 14px !important;
        }
        .pr-snippet-write-review-link {
          &::before {
            content: ' ';
            padding: 0;
          }
        }
      }
      .pr-snippet-stars-reco-inline {
        &.pr-snippet-minimal {
          .pr-snippet-read-and-write {
            @include breakpoint($medium-down) {
              text-align: center;
              display: block;
            }
          }
        }
      }
    }
    .pr-qa-snippet-container {
      padding-top: 10px;
      .pr-ask-question-link {
        &::before {
          content: ' ';
          padding: 0;
        }
      }
    }
  }
}

#pr-war-form {
  input {
    font-family: $font--subheading;
  }
}

.spp__container {
  #pr-reviewdisplay {
    .pr-helpful {
      &-yes {
        .pr-helpful-count {
          &:before {
            content: '有幫助 ';
            visibility: visible;
          }
        }
      }
      &-no {
        .pr-helpful-count {
          &:before {
            content: '沒有幫助 ';
            visibility: visible;
          }
        }
      }
    }
  }
}

.spp_customer_reviews {
  #pr-reviewdisplay {
    .p-w-r {
      .pr-snippet-read-and-write {
        span.pr-snippet-review-count {
          font-size: 34px !important;
        }
      }
    }
  }
}

@include breakpoint($medium-down) {
  .spp_customer_reviews {
    #pr-reviewdisplay {
      .p-w-r {
        .pr-snippet-read-and-write {
          span.pr-snippet-review-count {
            font-size: 23px !important;
          }
        }
        .pr-review-display {
          .pr-rd-footer {
            .pr-rd-bottomline {
              span {
                @include font--pwr--subheading();
                font-size: 14px;
              }
            }
          }
          .pr-rd-inner-side-content-block {
            span {
              font-size: 14px;
              color: $detail_color;
              font-weight: normal;
            }
            .pr-rd-details {
              time {
                color: $detail_color;
                font-size: 14px;
              }
            }
          }
          .pr-rd-helpful-text {
            @include font--pwr--subheading();
            font-size: 16px;
            display: inline;
            float: left;
          }
        }
        p.pr-rd-description-text {
          color: $color-black;
          font-size: 14px;
          font-family: $font--subheading;
        }
        .pr-rd-helpful-action {
          margin-bottom: 28px !important;
          width: 120%;
          .pr-helpful-btn {
            margin: 0px 5px;
            &.pr-helpful-no {
              border: none;
            }
          }
        }
      }
    }
  }
}
/* Mobile Potrait */
@include breakpoint(($portrait-only) (orientation portrait)) {
  .spp__container {
    #pr-reviewdisplay {
      .p-w-r {
        .pr-review-snapshot {
          a.pr-snippet-write-review-link {
            top: 148px;
          }
        }
      }
    }
  }
}
