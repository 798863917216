.gnav-mini-facials-block__cta {
  padding: 1px 1px 1px 1px;
  font-size: 22px;
  margin-top: 4px;
  width: 124px;
}

.gnav-mini-facials-block__learn-more {
  text-align: center;
  display: inherit;
}

.cal_book_now_thru {
  display: none;
}

@include breakpoint($landscape-up) {
  .appt-book.appointments {
    #booking-step2 {
      .appointment-select {
        .calendar-head {
          .cal-head-content {
            .date-picker {
              left: 37%;
              width: 26%;
            }
          }
        }
      }
    }
  }
}

.elc-user-state-logged-in {
  .user_account_dont_have {
    display: none;
  }
}

.elc-user-state-anonymous {
  .user_account_have {
    display: none;
  }
}

#appointment-book-container {
  .appt-book-page-header-content {
    .at-h1-appt-book-header {
      font-size: 1.1em;
      padding-top: 20px;
    }
  }
  .appointments-container {
    #booking-step2 {
      p {
        &.background_select {
          margin: 0px 16px 0 0;
          float: right;
          display: inline;
        }
      }
      .step-header {
        .section-header {
          &__inner {
            margin: 0 0 0 216px;
          }
        }
      }
    }
  }
  .appt-book-section {
    .location-selector {
      .selectBox-label {
        display: inline;
      }
    }
    .services-location {
      .selectBox-label {
        display: inline;
      }
    }
  }
}

.appt-book-overlay {
  &.appt-book-seltime-overlay {
    .overlay-content-container {
      width: 553px;
      min-height: 250px;
      top: 50%;
      left: 50%;
      margin-top: -95px;
      margin-left: -300px;
    }
  }
}

#appointment-cancellation {
  .appt-book-link {
    float: none;
    display: inline-block;
    margin-bottom: 10px;
    width: auto;
  }
}
