.page-header {
  .gnav-offers-block {
    &__list {
      .gnav-offers-block__list__item {
        a.gnav-offers-block__offer--link {
          padding-top: 2px;
        }
      }
    }
  }
}

.gnav-dropdown {
  &__footer {
    .gnav-dropdown__footer__inner {
      .gnav-dropdown__footer__copy {
        font-size: 16px;
      }
      .gnav-dropdown__footer__items {
        .gnav-dropdown__footer__item {
          font-size: 16px;
        }
      }
    }
  }
}

.gnav-util {
  &__content {
    .signin-block {
      .signin-block {
        &__main {
          .signin-block {
            &__title {
              margin-bottom: 25px;
            }
          }
          .signin-block {
            &__subtitle {
              margin-bottom: 0;
            }
          }
          .signin-block {
            &__toggle {
              line-height: 30px;
              padding-bottom: 6px;
            }
          }
        }
      }
    }
  }
}

.gnav-dropdown {
  .gnav-dropdown {
    &__content {
      .product-grid {
        &__item {
          .product-brief {
            &__header {
              height: 115px;
              .product-brief {
                &__subline {
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
      .gnav-by-collection {
        .gnav-by-collection {
          &__item {
            .gnav-by-collection-item {
              &__desc {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  &.gnav-dropdown--menu-cols-2 {
    .gnav-dropdown {
      &__content {
        .basic-tout {
          &__text {
            padding: 0;
            .basic-tout__text__img {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.site-offline {
  .site-offline {
    &__background {
      .site-offline {
        &__welcome {
          font-size: 135px;
          line-height: 1em;
        }
        &__help:after {
          display: none;
        }
        &__thankyou,
        &__checkback {
          font-family: SimSun, 'Futura-Book', sans-serif;
        }
      }
    }
  }
}

.page-header {
  &__nav {
    .page-header__nav {
      &__main {
        .menu__link__arrow {
          bottom: -7px;
        }
        .gnav-dropdown {
          top: 6px;
        }
      }
    }
  }
}

.section-store-locator {
  .store-locator {
    .store-locator__map-container {
      height: 100% !important;
    }
    .store-locator__main {
      min-height: 650px !important;
      @include breakpoint($medium-down) {
        min-height: 850px !important;
      }
      .store-locator__result {
        height: auto;
      }
    }
  }
}

.section-store-locator {
  .store-locator-overlay-cbox {
    .store-locator {
      &__my-store-saved {
        padding: 50px 0 0;
        width: 85%;
      }
    }
    #cboxContent {
      padding: 25px;
    }
  }
}

.spp-page {
  .product-full {
    &__info {
      .product-info {
        &__main-right {
          .product-info {
            &__selects {
              width: 297px;
              .product-sku-select {
                width: 60%;
              }
            }
          }
        }
      }
      .product-add-to-bag,
      .product-add-to-waitlist {
        width: 50%;
        margin: 0 auto;
      }
    }
  }
}

.mpp {
  &__regimen-quiz {
    .regimen-quiz {
      &__content {
        .regimen-quiz {
          &__grouping--age {
            .regimen-quiz {
              &__intro-text {
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }
}

#popover_container {
  #hppopup {
    img {
      width: 100%;
    }
  }
}

#colorbox {
  #cboxWrapper {
    #cboxContent {
      width: 475px;
      text-align: center;
      padding: 0px;
    }
  }
}

#cboxWrapper {
  #cboxContent {
    #cboxLoadedContent {
      overflow: hidden !important;
    }
  }
}

#viewcart {
  #cboxOverlay {
    background: none;
  }
}

.page-header__utils .menu .menu__list {
  .menu__item--lvl-1 {
    &.mini-facials {
      .menu__link--lvl-1 {
        font-family: $font--text--italic;
      }
    }
  }
}

.site-email-signup {
  &__subtitle {
    font-size: 18px;
  }
}
// Endeca Updated Search
.utility-nav-active,
.enable-endeca__search {
  .search_results__filter_clear {
    p {
      a.button {
        width: 43%;
      }
    }
  }
}
