$border-color-grey: $color-light-gray;

#content_breadcrumb {
  margin-right: 40px;
  width: 640px;
  padding: 0px 20px;
  ol.checkout-progress {
    margin-top: 25px;
  }
  li {
    display: inline;
    margin-right: 40px;
    width: 100%;
    color: $color-dark-gray;
    font-size: 13px;
  }
}

.checkout.viewcart {
  section#promo-panel {
    ul.error_messages {
      padding: 0 1em;
    }
  }
}

.checkout.review {
  #content_breadcrumb {
    li.review {
      color: $color-dark-black;
      font-weight: bold;
    }
  }
  #continue-checkout-btm {
    float: right;
  }
}

.checkout.shipping {
  #content_breadcrumb {
    li.shipping {
      color: $color-dark-black;
      font-weight: bold;
    }
  }
}

#confirm_checkout {
  #content_breadcrumb {
    li.confirm {
      color: $color-dark-black;
      font-weight: bold;
    }
  }
  .terms-accept {
    .form-item {
      width: 100%;
    }
  }
}

$color-black: $color-darker-gray;
$border-bottom-color: $color-light-gray;

.checkout__content {
  .cart-items__header {
    &--thumb {
      width: 11%;
    }
    &--products {
      width: 40%;
    }
    &--price {
      width: 16%;
    }
    &--qty {
      width: 20%;
      text-align: left;
    }
    &--total {
      width: 13%;
    }
  }
  .cart-items__item {
    &--desc {
      width: 40%;
    }
    &--price {
      width: 17%;
    }
    &--total {
      width: 12%;
    }
  }
}

#bottom-panel {
  border-top: none;
}

#shipping-panel,
#billing-panel {
  .address-container {
    width: 45%;
    float: left;
  }
  .choose-address-control {
    width: 50%;
    float: left;
    padding-right: 15px;
  }
}

#samples-panel {
  ul.product-list {
    li.product:hover {
      border: 2px solid $color-black;
    }
  }
  header {
    h2 {
      margin: 12px auto;
    }
  }
}

#delivery-instructions {
  padding-top: 10px;
  textarea {
    height: 75px;
    width: 300px;
  }
  span.required_mark {
    display: inline;
    float: left;
  }
}

#delivery-panel {
  #delivery_time_display,
  #delivery_method {
    width: 40%;
  }
}

.checkout {
  #signin-block {
    .checkout__panel--content {
      p {
        height: 42px;
      }
      .chk-guide {
        padding-right: 1.33333em;
      }
    }
    input[type='email'],
    input[type='password'],
    input[type='text'] {
      margin: 5px 0px;
    }
  }
}

#checkout_shipping {
  div.sub-block.create-account {
    fieldset[title='create-account'] {
      margin-top: 20px;
      .email-disp {
        display: block;
      }
      fieldset.fs.checkout_registration {
        input[type='email'],
        input[type='password'],
        input[type='text'] {
          margin-top: 5px;
        }
      }
    }
  }
  #deliver-to-me {
    #address_form_container {
      fieldset[title='your-address'] {
        .input_phone_1,
        .input_phone_2,
        input[type='email'],
        input[type='password'],
        input[type='text'],
        select {
          margin-top: 5px;
        }
      }
    }
  }
  .terms_conditions_container {
    .privacy_policy_container {
      label {
        margin-top: 2px;
      }
    }
  }
  .btn_submit {
    clear: both;
    margin: 10px 0 20px;
    position: relative;
    text-align: right;
  }
  #pickup-options {
    img {
      width: 50px;
      height: 50px;
    }
    label::before {
      margin-right: 8px;
      margin-top: 2px;
    }
    label {
      display: flex;
    }
    .pickup-label {
      padding: 10px 0px 5px 10px;
      float: left;
    }
    .pickup-store {
      padding: 5px 10px 5px 10px;
      float: left;
    }
    .store-btn {
      height: 48px;
      line-height: 3.2;
      margin: 5px 0;
      a {
        text-decoration: none;
      }
    }
  }
  .address-container.shipping-address,
  .address-container.billing-address {
    float: left;
    width: 50%;
  }
  .address_controls {
    float: left;
    margin-top: 20px;
    width: 46%;
    li {
      padding-top: 5px;
    }
    li:first-child {
      padding-top: 10px;
    }
    #choose-address {
      a.address-select {
        width: 100%;
      }
    }
  }
  .checkout__content {
    width: 75%;
  }
  .checkout__sidebar {
    width: 25%;
    &:after {
      width: 25%;
      left: 75%;
    }
  }
  .discount.value,
  .subtotal.value,
  .tax.value,
  .order-summary__total-value {
    width: 35%;
  }
  .discount.label,
  .subtotal.label,
  .tax.label,
  .order-summary__total-label {
    width: 65%;
  }
  div.carrier-type {
    a.invoice-carrier-type {
      width: 100%;
    }
  }
  #delivery-options {
    select.error {
      border-color: $color-red;
    }
    div#delivery_method {
      width: 40%;
      a.selectbox {
        width: 100%;
      }
      select {
        margin-top: 5px;
      }
    }
    div#delivery_time_display {
      width: 20%;
      a.selectbox {
        width: 100%;
      }
      select {
        margin-top: -13px;
      }
    }
  }
  div#corporate-options-wrapper {
    .option.corporate_name,
    .option.corporate_tax_id {
      input {
        width: 96%;
        display: block;
      }
    }
  }
  div#invoice-options-wrapper {
    .invoice-method-options {
      label {
        display: block;
        margin-bottom: 5px;
      }
    }
    .invoice-address-options {
      label {
        display: block;
        margin: 10px;
      }
    }
    .option.carrier-type,
    .option.npoban {
      width: 96%;
    }
    .option.invoice-address {
      input {
        width: 96%;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
  .gift-options-wrapper {
    #to,
    #from {
      margin-bottom: 20px;
      input {
        display: block;
        width: 96%;
      }
    }
    #message {
      textarea {
        display: block;
        margin-bottom: 5px;
      }
      #msg-counter {
        margin-top: 5px;
      }
    }
  }
  div.carrier-id {
    margin-top: 10px;
  }
  div.invoice-options-container-donate {
    div.npoban {
      a.selectbox {
        width: 100%;
      }
    }
  }
  #content_breadcrumb li.shipping {
    color: $color-dark-black;
  }
  #billing_address_form_container {
    input[type='text'] {
      width: 100%;
    }
    select,
    .selectBox {
      width: 100%;
    }
    .district {
      padding-left: 10px;
      width: 50%;
      clear: none;
      float: left;
    }
  }
}

fieldset.fs {
  .gift_form_container {
    .form_element {
      width: 100%;
      input {
        width: 50%;
      }
    }
  }
}

#review-address-info {
  .trans_detail_item {
    float: left;
    margin-bottom: 25px;
    margin-right: 25px;
    position: relative;
    width: 47%;
    word-wrap: break-word;
  }
  a.change_link {
    position: absolute;
    right: 0;
    top: 23px;
  }
  h2.checkout__panel--heading {
    display: block;
    margin: 21px 0;
  }
}

.review {
  #review-panel {
    border-top: 0px;
  }
  .cart-item.cart-items__item.product.checkout-table {
    border-bottom: 0px;
  }
  #review-address-info {
    h4 {
      margin-bottom: 10px;
    }
    #delivery-time,
    #invoice_address {
      margin-top: 10px;
    }
  }
  .payment-panel {
    .header-section {
      clear: both;
      display: inline-block;
      width: 100%;
    }
    .non-installment-options-header,
    .installment-options-header {
      float: left;
      width: 75%;
    }
    .nccc-media {
      float: right;
      padding: 5px 5px 0 0;
    }
    .non-installment-options,
    .linepay-options,
    .installment-options {
      border: 1px solid $border-color-grey;
      margin: 0 20px 10px 0;
    }
    .non-installment-options-header,
    .installment-options-header,
    .linepay-options-header {
      padding: 10px;
    }
    .non-installment-options-radios,
    .installment-options-radios,
    .linepay-options-radios {
      padding-left: 10px;
      margin: 0 0 0.8em 0;
    }
    .nccc-media img {
      height: 25px;
      width: 35px;
    }
    .linepay-options-radios img {
      width: 74px;
      height: 24px;
    }
  }
}

#payment-container {
  .bankname {
    width: 40%;
  }
  .bank_card {
    float: left;
    margin-left: 4%;
    width: 10%;
  }
  .bankname {
    select {
      margin: 5% 0 0 10%;
      clear: both;
    }
    p {
      margin-left: 10%;
    }
  }
}

.checkout__sidebar {
  .checkout__panel {
    border-top: 1px solid $color-light-gray;
  }
}

.confirm-page {
  .checkout__panel--content p {
    font-size: 16px;
  }
  .return-link-container {
    padding: 15px;
  }
}

.new_user__panel {
  .submit_btn {
    padding: 1em;
  }
  .guest_user {
    margin-top: 1em;
  }
}

.checkout {
  #sign-in-panel {
    .form-item.submit {
      bottom: 15px;
    }
  }
  &.shipping,
  &.review {
    li[id='offer_bogo..'] {
      display: none;
    }
    .return-link {
      float: left;
      height: 56px;
      line-height: 4;
      margin-top: 20px;
      padding-right: 15px;
      color: $color-grey;
      font-size: 14px;
      font-family: $font--cta;
      text-decoration: underline;
    }
    #continue-checkout {
      float: right;
      width: 250px;
      margin-right: 20px;
    }
    .continue-button-wrapper {
      clear: right;
      margin-top: 20px;
      .form-item {
        float: right;
        width: 250px;
        padding-right: 20px;
      }
    }
  }
  &.shipping,
  &.review,
  &.viewcart,
  &.checkout-single-page {
    font-family: $font--text;
    h2 {
      font-family: $font--text;
      font-size: 1.5em;
      letter-spacing: 0;
      &.checkout__panel--heading {
        font-family: $font--subheading;
        font-size: 24px;
      }
    }
    h3 {
      font-family: $font--text;
      font-size: 1.17em;
      letter-spacing: 0;
      &.account-page__section--subheading {
        font-family: $font--subheading;
        font-size: 24px;
        letter-spacing: 0.05em;
      }
    }
    h4 {
      font-family: $font--text;
      font-size: 18px;
      letter-spacing: 0;
    }
    p {
      font-size: 18px;
      letter-spacing: 0;
    }
    .newsletter-info__text--new {
      font-size: 18px;
      letter-spacing: 0;
    }
    .trans_detail_item {
      font-family: $font--text;
    }
    .form-submit,
    .checkout-buttons {
      height: 48px;
      line-height: 48px;
    }
  }
  &.samples-page {
    .details,
    .product-name,
    .product-items_content,
    .sample-select-button {
      font-family: $font--subheading;
    }
  }
  &.pg_wrapper {
    #checkout-wrapper {
      h2 {
        font-family: $font--text;
        font-size: 1.5em;
      }
      div {
        font-family: $font--text;
      }
    }
    .checkout__sidebar {
      .checkout__panel--content {
        p {
          font-family: $font--text;
        }
      }
    }
  }
}

#viewcart,
#shipping {
  #cboxContent {
    #cboxLoadedContent {
      h1 {
        font-family: $font--text;
        font-size: 2em;
      }
      h2 {
        font-family: $font--text;
        letter-spacing: 0;
        font-size: 1.5em;
      }
      p {
        font-family: $font--text;
        font-size: 18px;
      }
      & > div:first-child {
        height: inherit;
        & > .content:first-child {
          height: inherit;
          overflow-y: scroll;
        }
      }
    }
    #address_form_container {
      height: inherit;
      overflow-y: scroll;
    }
    #cboxClose:hover {
      background-position: 0 0;
    }
  }
}

#confirm_checkout {
  .gnav-util--account {
    display: none;
  }
  fieldset.form-guest-submit {
    padding: 0 0 10px 1px;
  }
  #forgot-pass {
    margin-top: 10px;
  }
  #print-order {
    margin: 20px 1px;
    .link.details-arrow.order-detail-item-link {
      color: $color-white;
      line-height: 3px;
    }
  }
  .checkout__sidebar {
    .return-link-container {
      .link.checkout-buttons.button {
        color: $color-white;
      }
    }
  }
}

#confirmation-page {
  .new_user__panel {
    padding-top: 20px;
  }
}

.address-overlay.adpl,
.checkout .adpl {
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='search'],
  input[type='password'],
  select,
  textarea {
    &.error {
      border-color: $color-red !important;
      border: 1px solid;
    }
  }
}

.checkout__content {
  .cart-items__item--qty {
    select {
      height: 32px;
      line-height: 28px;
      width: 100%;
    }
  }
}

.checkout__panel--continue-buttons {
  display: flex;
  align-items: baseline;
  text-align: left;
  .checkout-buttons__container {
    width: 75%;
    text-align: right;
  }
  .checkout-buttons.button {
    color: $color-white;
  }
}

#continue-checkout {
  margin: 20px 1px;
}

#cboxLoadedContent {
  .additional_links {
    padding: 20px;
  }
}

#payPage {
  form {
    tbody {
      line-height: 45px;
      .EXPIREDATE_BODY {
        display: flex !important;
        .EXPIREDATE_SELECT {
          min-width: 0 !important;
          width: 85px !important;
        }
      }
    }
  }
}

.customer-service .sidebar-page__content h2 {
  font-size: 30px;
  line-height: 1.8;
  border-bottom: 1px solid #a4a4ab;
  margin-bottom: 20px;
}

#colorbox .customer-service .sidebar-page__content {
  width: 100%;
  padding: 0;
}

#cboxContent {
  padding: 25px;
}

.order-confirmation {
  .account-page.order-details-page {
    .account-utilities {
      .profile-pic-main,
      .account-utilities__section {
        display: none;
      }
    }
  }
}

#samples-panel {
  .sample-products {
    .product-list {
      .product-items_content {
        min-height: 88px;
      }
    }
  }
}

.checkout-page {
  &.shipping-page {
    .gift-message {
      #to,
      #from,
      #message {
        width: 218px;
      }
      #message {
        textarea {
          width: 210px;
        }
      }
    }
  }
}

.no_gwp_samples {
  padding-top: 20px;
  .go-shopping {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.lbo {
  &__container {
    .lbo {
      &__message {
        &--content {
          padding-left: 150px;
          margin-left: 150px;
        }
      }
      &__banner {
        padding-bottom: 7px;
        padding-top: 2px;
        &--close {
          margin-right: 196px;
          float: right;
          margin-top: -89px;
          cursor: pointer;
        }
      }
    }
  }
}
