#recommended-products-panel {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
  .recommended-item {
    @include breakpoint($medium-up) {
      text-align: left;
    }
    &__product-image {
      &,
      &__image {
        @include breakpoint($medium-up) {
          max-height: 300px;
        }
      }
    }
    &__product {
      &-info {
        text-align: left;
        padding: 0;
        &__meta-heada {
          display: none;
        }
        &__name {
          margin-top: 10px;
          font-family: $font--futura-demi;
          font-size: 15px;
        }
        &__subname {
          @include breakpoint($medium-up) {
            font-family: $font--futura-medium;
            font-size: 15px;
            text-transform: capitalize;
            padding-bottom: 10px;
            line-height: 18px;
          }
        }
        &__benefit-label {
          @include breakpoint($medium-up) {
            font-weight: bold;
          }
        }
        &__price {
          @include breakpoint($medium-up) {
            line-height: 2;
            display: inline-block;
            min-width: 76px;
            font-family: $font--futura-book;
            width: 0;
            font-size: 15px;
            margin: 15px 0;
          }
        }
        &__review-snippet {
          padding: 5px 0;
        }
      }
    }
    &__cta {
      display: table-cell;
      padding-top: 10px;
      &-spp-link {
        vertical-align: bottom;
        display: table-cell;
        padding-left: 1em;
        text-decoration: underline;
        font-size: 15px;
        @include breakpoint($medium-portrait-only) {
          padding-left: 0.5em;
          font-size: 16px;
        }
      }
    }
    .addtobag_cta {
      @include breakpoint($medium-up) {
        border-style: none;
      }
      @include breakpoint($medium-portrait-only) {
        padding: 0 12px;
        font-size: 14px;
      }
    }
    .sku-list-sizes {
      display: inline-block;
      &__list {
        @include breakpoint($medium-portrait-only) {
          display: inline-grid;
        }
      }
      &__item {
        @include breakpoint($medium-portrait-only) {
          padding-top: 10px;
        }
      }
      &__button {
        font-size: 12px;
        padding: 0 5px;
      }
    }
    .product-sku-price__value {
      font-size: inherit;
    }
    &__product-image__image {
      &.product-image__image--alt {
        opacity: 0;
        position: absolute;
        top: 54px;
        max-height: 254px;
        transition: opacity 0.3s;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .product-brief {
    &__shades {
      @include breakpoint($medium-up) {
        max-width: 150px;
        min-width: 150px;
        .slick-next {
          left: 157px;
        }
      }
      &.slick-slider {
        float: right;
        margin: 15px;
      }
    }
    &__shade {
      height: 22px;
      width: 22px;
    }
  }
}

.samples-page--refresh {
  .samples-panel,
  .wp-samples-panel {
    &__cta-spp-link {
      display: block;
      font-family: $font--futura-book;
    }
  }
  #samples-panel {
    .product-list {
      padding: 20px 0;
      li {
        &.product {
          border: 2px solid $color-white;
          clear: none;
          @include breakpoint($medium-up) {
            &:nth-child(3n + 1) {
              clear: both;
            }
          }
          .product-brief {
            &__benefit {
              padding-top: 5px;
            }
            &__subline {
              font-family: $font--futura-book;
            }
          }
          .product-items_content {
            height: 90px;
          }
          .product-size,
          .product-desc,
          .product-price {
            font-family: $font--futura-book;
          }
          .skus {
            li.sku {
              width: 142px;
              @include breakpoint($medium-up) {
                width: 180px;
              }
              @include breakpoint($ipad-pro-portrait-only) {
                width: 255px;
              }
              &.disabled {
                background-color: $color-light-gray;
              }
              .sample-select-button {
                font-family: $font--futura-book;
              }
            }
          }
        }
      }
    }
    .continue-shopping-outer-container {
      .continue-shopping-inner-container {
        text-align: center;
        margin: 0;
        @include breakpoint($medium-up) {
          text-align: right;
        }
        .continue-shopping-button {
          &.button {
            margin-left: 0;
            width: 250px;
            line-height: 56px;
            height: 56px;
            font-family: $font--subheading;
          }
          @include breakpoint($medium-up) {
            margin-left: 20px;
          }
        }
      }
      .no-thanks-returncart {
        color: $color-grey;
      }
    }
  }
}

.gnav-util {
  &__content {
    .cart-block,
    .cart-item {
      &__img {
        overflow-wrap: break-word;
      }
      &__subheading,
      &__title,
      &__item-count,
      &__footer__subtotal__label,
      &__footer__button,
      &__items__view-bag,
      &__shipping__title,
      &__title__text {
        font-family: $font--futura-book;
      }
      &__footer__subtotal__value,
      &__price,
      &__qty,
      &__sku-label {
        font-family: $font--futura--text;
      }
    }
  }
}

.checkout {
  &__content {
    .cart-items {
      &__item--thumb {
        img {
          overflow-wrap: break-word;
        }
      }
    }
  }
}
