/* Order confirmation Page */
#confirmation-panel {
  .social-login {
    padding-left: 0;
    &__email-opt-in {
      padding-top: 20px;
    }
  }
}
/* Gnav */
.gnav-util__content__inner {
  .social-login.gnav {
    .social-login__email-opt-in {
      margin-left: 23px;
      @include breakpoint($medium-up) {
        margin-left: 26px;
      }
    }
    .social-login__terms {
      padding-left: 23px;
      padding-bottom: 15px;
      @include breakpoint($medium-up) {
        padding-left: 26px;
      }
    }
  }
}
/* Checkout Signin Page */
.checkout-single-page {
  #sign-in-panel {
    .social-login {
      padding-top: 0;
      margin: 0;
      &__divider {
        width: 90%;
        @include breakpoint($medium-up) {
          width: 100%;
        }
      }
      &__email-opt-in {
        margin-left: 20px;
        margin-top: 15px;
      }
      &__terms {
        padding-left: 20px;
      }
    }
  }
}
/* Write a Review */
.write_a_review__login {
  .email_check {
    .social-login {
      &__email-opt-in {
        margin: 0;
      }
      &__terms {
        padding: 0;
      }
    }
  }
}
/* Account signin page */
.account-main-content__wrapper {
  .social-login {
    &__divider {
      width: 90%;
      @include breakpoint($medium-up) {
        width: 95%;
      }
    }
    &__email-opt-in {
      margin-left: 15px;
      @include breakpoint($medium-up) {
        margin-left: 26px;
      }
    }
    &__terms {
      padding-left: 15px;
      @include breakpoint($medium-up) {
        padding-left: 26px;
      }
    }
  }
}
